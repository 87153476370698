/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import Loading from '@domui-components/Loading';
import Metadata from '@domui-utils/workflow/metadata';

const Program: React.FC = () => {
  const [programData, setProgramData] = useState<any>({});

  useEffect(() => {
    async function fetchProgramData() {
      const wfService = new Metadata();
      const response = await wfService.getProgram();
      setProgramData(response);
    }
    fetchProgramData();
  }, []);

  if (!programData) {
    return <Loading />;
  }

  return (
    <div>
      {!!programData?.length && (
        <div style={{ alignItems: 'center' }} className="max-w-4xl mb-8">
          <h3>Program Page</h3>
          <ul>
            {programData.map((item: any) => (
              <div style={{ padding: 20 }} key={item.id}>
                <li className="mb-0 last:mb-4">
                  {`id : ${item.id}`} {`value : ${item.value}`}
                  <div
                    style={{
                      marginLeft: 7,
                      marginRight: 7,
                      marginTop: 7,
                    }}
                  >
                    Fields :
                  </div>
                  {item?.Fields?.map((item: any) => (
                    <div key={item.id}>
                      <li>
                        <div style={{ padding: 20 }}>
                          <div>{` id :  ${item.id}`}</div>
                          <div>{`  value :  ${item.value}`}</div>
                          <div>{`  Description :  ${item.Description}`}</div>
                        </div>
                      </li>
                    </div>
                  ))}
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Program;
